//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { OVERLAY_SEARCH } from './overlay'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
// Fonction qui gère le label des input
export function fixInput() {
  // Si le focus est sur le champ, on enlève la classe vide pour que le label se déplace
  $('input').focusin(function() {
    $(this).removeClass('notEmpty')
  })

  // Si le champ est vide, on replace le label à sa position d'origine au focusout
  $('input').focusout(function() {
    ($(this).val() != '' ? $(this).addClass('notEmpty') : $(this).removeClass('notEmpty'))
  })
}


// Permet de supprimer le contenu d'un input
export function clearInput() {
  let i
  let btn = document.getElementsByClassName('clearInput')
  let btnLength = btn.length

  for (i = 0; i < btnLength; i++) {
    btn[i].addEventListener('click', (e) => {
      let input = e.currentTarget.parentElement.getElementsByTagName('input')[0]
      input.value = ''
      input.focus()
      input.parentElement.classList.remove('notEmpty')
      input.classList.remove('valid')
      $('#fastResults').stop(true, false).animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    })
  }
}


// Ajoute une classe en considérant si le input est plein ou vide
export function checkInput() {
  let i
  let input = document.querySelectorAll('input')
  let inputLength = input.length

  for (i = 0; i < inputLength; i++) {
    input[i].addEventListener('input', function(e) {
      if (e.target.value.length >= 1) {
        e.target.parentElement.classList.add('notEmpty')
      } else {
        e.target.parentElement.classList.remove('notEmpty')
      }
    })
  }
}


// Affiche ou masque les résultats rapide
// export function fastResults(form) {
//   $('#fastResults').stop(true, false).animate({ height: 'hide' }, { duration: 0 })

//   $(form).keyup(function() {
//     if ( $(this).val().length >= 2 ) {
//       $('#fastResults').stop(true, false).animate({ height: 'show' }, { duration: 300, specialEasing: { height: 'easeOutExpo' }})
//     } else {
//       $('#fastResults').stop(true, false).animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeOutExpo' }, complete: function() {
//         $('#fastResults').css('height','')
//       }})
//     }
//   })
// }

// AUTOCOMPLETE
export function fastResults(form) {

  // Initialisation.
  $('#fastResults').stop(true, false).animate({ height: 'hide' }, { duration: 0 })

  var ajaxRequest = false //Indique si une requete ajax pour l'autocomplete est déjà en cours
  var searchInput = $(form + ' .autocomplete') //Pointeur sur le input de recherche

  $(searchInput).keyup(function(e) {

    if( ajaxRequest ) {
      // Si une requête est déjà en cours, l'annuler pour faire place à la prochaine
      ajaxRequest.abort()
    }
    
    var keycode = (e.keyCode ? e.keyCode : e.which)
    
    if ( keycode != 13 )
    {
      if ( $(this).val().length >= 4 ) {

        ajaxRequest = $.request('rechercheAutocomplete::onFetchAutocomplete', {
          data: { q: $(this).val() },
          update: { 'rechercheAutocomplete::autocomplete':'#fastResults'},
          complete: function (data) {
            // Results loaded.
            $('#fastResults a').on('click', function(){
              OVERLAY_SEARCH.close()
            })
            ajaxRequest = false
            $('#fastResults').stop(true, false).animate({ height: 'show' }, { duration: 300, specialEasing: { height: 'easeOutExpo' }})
          },
        })

      } else {

        $('#fastResults').stop(true, false).animate({ height: 'hide' }, { duration: 300, specialEasing: { height: 'easeOutExpo' }, complete: function(){
          // S'assurer de pas avoir un height:0px qui apparait suite a des frappes rapides.
          $('#fastResults').css('height','')
        }})
      }
    }
  })
}


// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  if($('html')[0].lang == 'en') {
    return {
      required: 'This field is required.',
      select: 'Please chose an option.',
      email: 'Please enter a valid email address.',
      phone: 'Please enter a valid phone number.',
      postale: 'Please enter a valid ZIP code.',
      date: 'Please enter a valid date.',
      accept: 'The file must be a document pdf, docx, doc or rtf.',
      file: 'Please provide a adequate file.'
    }
  } else {
    return {
      required: 'Ce champ est obligatoire.',
      select: 'Veuillez sélectionner une option.',
      email: 'Veuillez fournir une adresse électronique valide.',
      phone: 'Veuillez fournir un numéro de téléphone valide.',
      postale: 'Veuillez fournir un code postal valide.',
      date: 'Veuillez fournir une date valide.',
      accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
      file: 'Veuillez lier le fichier adéquat pour ce champ.',
      keywords: 'Veuillez entrer vos mots-clés.',
      keywordslength: 'Entrez un mot-clé d’au moins 3 lettres.',
    }
  }
}



// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages, handler) {
  $.validator.setDefaults({
    ignore: []
  })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (element.attr('type') == 'radio') {
        error.insertBefore(element.closest('.radiobox-field').find('.errorblock'))
      } else if (element.attr('type') == 'checkbox') {
        error.insertBefore(element.closest('.checkbox-field').find('.errorblock'))
      } else if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    },
    submitHandler: function() {
      if (typeof handler != 'undefined') {
        handler()
        return false
      }
      return true
    }
  })
  $(submit).click(function() {
    $(form).submit()
  })
}


// Validation du formulaire de contact
/*export function formContact() {
  validationEmail()
  formatPhone('#formContact-phone')
  var m = getMessages()
  var rules = {
    prenom:            { required: true },
    nom:               { required: true },
    courriel:          { required: true, email: true },
    telephone:         { required: true, minlength: 14 },
    message:           { required: true },
  }
  var messages = {
    prenom:            { required: m.required },
    nom:               { required: m.required },
    courriel:          { required: m.required, email: m.email },
    telephone:         { required: m.required, minlength: m.phone },
    message:           { required: m.required },
  }

  formValidation('#formContact', '#submitContact', rules, messages)
}*/

// Validation du formulaire de recherche
export function formSearch(formID,formSubmit) {

  var m = getMessages()
  var rules = {
    q: { required: true, minlength:3 },
  }
  var messages = {
    q: { required: m.keywords, minlength: m.keywordslength },
  }

  formValidation(formID, formSubmit, rules, messages)
}