//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/Overlay/Overlay.js'
import { OBSERVER } from '../main'
import ShareButtons from 'share-buttons/dist/share-buttons'
//-----------------------------------------------------------------------------------------------------------------


// Fonction gérant l'overlay de partage
export function overlayShare() {
  new Overlay({
    name: 'Share',
    structure: {
      buttons: {
        open: '.js-open-overlay-share',
        close: '.js-close-overlay-share',
      }
    },
    options: {
      speed: 800,
    }
  })

  // Message de confirmation de copie de l'URL ----------------
  function confirmClipboardCopy() {
    setTimeout(() => {
      alert('L’adresse URL a bien été copiée dans votre presse-papier.')
    }, 300)
  }
  OBSERVER.add('confirmClipboardCopy', 'click', confirmClipboardCopy, '.js-share-copy')
  OBSERVER.on('confirmClipboardCopy')

  // Gestion de l'ouverture de l'overlay ----------------------
  function openShareOverlay(e) {
    if (e.currentTarget.dataset.rubric) {
      window.history.pushState(null, null, `#${e.currentTarget.dataset.rubric}`)
    }
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add('openShareOverlay', 'click', openShareOverlay, '.js-open-overlay-share')
  OBSERVER.on('openShareOverlay')

  // Gestion de la fermeture de l'overlay ---------------------
  function closeShareOverlay() {
    window.history.replaceState(null, null, ' ')
    document.querySelector('#Share-buttons').setAttribute('data-url', window.location.href)
    ShareButtons.update()
  }
  OBSERVER.add('closeShareOverlay', 'click', closeShareOverlay, '.js-close-overlay-share')
  OBSERVER.on('closeShareOverlay')
}


// Fonction gérant le menu mobile
export function overlayDropdown() {
  let doc = document, i

  new Overlay({
    name: 'Dropdown',
    structure: {
      openingClass: 'openingDropdown',
      closingClass: 'closingDropdown',
      buttons: {
        close: '.closeOverlay',
        toggle: '.btnDropdown',
        switch: '.btnSearch'
      }
    },
    event: {
      name: 'click',
      sensitive: true,
      speed: 800,
      opening: {
        callback: function() {
          let images = doc.querySelectorAll('#overlayDropdown img')
          let imagesLength = images.length
          for (i=0; i<imagesLength; i++)
            images[i].classList.add('remove')

          setTimeout( () => {
            images = doc.querySelectorAll('#overlayDropdown img')
            imagesLength = images.length
            for (i=0; i<imagesLength; i++) {
              if(images[i].classList.contains('remove'))
                images[i].remove()
            }
          }, 300)
        }
      },
      closing: {
        callback: function() {
          setTimeout( () => {
            document.querySelector('#overlayDropdown picture').innerHTML = ''
          }, 500)
        }
      }
    },
    options: {
      goToSelector: 'html'
    }
  })
}


// Fonction gérant le menu mobile
export function overlayMenu() {
  new Overlay({
    name: 'Menu',
    structure: {
      openingClass: 'openingMenu',
      closingClass: 'closingMenu',
      buttons: {
        close: '.closeOverlay',
        toggle: '.btnMenu',
        switch: '.btnSearch'
      }
    },
    event: {
      name: 'click',
      sensitive: true,
      speed: 800
    },
    options: {
      goToSelector: 'html',
    }
  })

  $('.btnMenu, .btnSearch').on('click', function() {
    $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    $('.groundFloor li.open').removeClass('open')
    $('.groundFloor').removeClass('oneIsActive')
  })

  $('.groundFloor .btnSubLvl1').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.groundFloor li.open .subLvl1').animate({ height: 'hide' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    $('.groundFloor li.open').removeClass('open')
    $('.groundFloor').removeClass('oneIsActive')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() {
        $(chosen).parent().addClass('open')
        $('.groundFloor').addClass('oneIsActive')
      }, 0)
      $('.subLvl1', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}


// Fonction gérant l'overlay de la recherche en mobile
export let OVERLAY_SEARCH
export function overlaySearch() {
  let doc = document
  OVERLAY_SEARCH = new Overlay({
    name: 'Search',
    structure: {
      openingClass: 'openingSearch',
      closingClass: 'closingSearch',
      dataset: 'search',
      buttons: {
        toggle: '.btnSearch',
        close: '.closeOverlay, #overlay',
        switch: '.btnMenu, .btnDropdown'
      }
    },
    event: {
      name: 'click',
      speed: 800,
      opening: {
        callback: function() {
          setTimeout(() => {
            doc.getElementById('f_search_input').focus()
          }, 800)
        }
      }
    },
    options: {
      goToSelector: 'html'
    }
  })
}


// Fonction gérant l'overlay des parcours de la carte interactive en mobile
export function overlayParcours() {
  new Overlay({
    name: 'Parcours',
    structure: {
      openingClass: 'openingParcours',
      closingClass: 'closingParcours',
      buttons: {
        toggle: '.btnParcours'
      }
    },
    event: {
      name: 'click',
      speed: 800,
      closing: {
        callback: function(e) {
          $('.drawersParcours li.open .drawer').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
          $('.drawersParcours li.open').removeClass('open')
        }
      }
    }, 
    options: {
      noScroll: false
    }
  })
}


// Fonction gérant l'overlay d'une oeuvre de la carte interactive
export function overlayOeuvre() {
  new Overlay({
    name: 'Oeuvre',
    structure: {
      openingClass: 'openingOeuvre',
      closingClass: 'closingOeuvre',
      buttons: {
        close: '.closeOverlay, .btnParcours',
      }
    },
    event: {
      name: 'click',
      speed: 800,
    }, 
    options: {
      noScroll: false
    }
  })
}