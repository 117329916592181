//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'

import {
  View, Home, Map, DetailParcours, DetailOeuvre, DetailArtiste, SearchResults
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------


export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'sdtView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()


  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de la carte interactive __________
  Barba.BaseView.extend({
    namespace: 'mapView',
    onEnter:          function() {},
    onEnterCompleted: function() { Map.initMap()  },
    onLeave:          function() { Map.leaveMap() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de détail d'un parcours __________
  Barba.BaseView.extend({
    namespace: 'detailParcoursView',
    onEnter:          function() {},
    onEnterCompleted: function() { DetailParcours.initDetailParcours()  },
    onLeave:          function() { DetailParcours.leaveDetailParcours() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de détail d'une oeuvre __________
  Barba.BaseView.extend({
    namespace: 'detailOeuvreView',
    onEnter:          function() {},
    onEnterCompleted: function() { DetailOeuvre.initDetailOeuvre()  },
    onLeave:          function() { DetailOeuvre.leaveDetailOeuvre() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de détail d'une artiste __________
  Barba.BaseView.extend({
    namespace: 'detailArtisteView',
    onEnter:          function() {},
    onEnterCompleted: function() { DetailArtiste.initDetailArtiste()  },
    onLeave:          function() { DetailArtiste.leaveDetailArtiste() },
    onLeaveCompleted: function() {}
  }).init()


  // Page de Résultats de recherche __________
  Barba.BaseView.extend({
    namespace: 'searchResultsView',
    onEnter:          function() {},
    onEnterCompleted: function() { SearchResults.initSearchResults()  },
    onLeave:          function() { SearchResults.leaveSearchResults() },
    onLeaveCompleted: function() {}
  }).init()
}
