//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { SCROLLER, PARALLAX, SCROLLFIRE, OBSERVER } from './main.js'
import svg4everybody from 'svg4everybody/dist/svg4everybody.legacy.js'
import { removeHoverOnMobile, fixObjectFitIE } from './functions/helper.js'
import { ieCustomEvent } from './functions/polyfills.js'
import {
  overlayShare,
  overlayDropdown,
  overlayMenu,
  overlaySearch,
  overlayOeuvre,
  overlayParcours,
} from './functions/overlay.js'
import { displayImages, sizeH1, drawers, backFixed, sticky, dragAndSlide, playAudio, slugifyProvider, scrollToBlock } from './functions/functions.js'
import { clearInput, checkInput, fastResults, fixInput, clearSearchInput, formSearch } from './functions/forms.js'
import { slickImages } from './functions/slicks.js'
import { mapPinMobile, googleMap, destroyMap } from './functions/map.js'
//-----------------------------------------------------------------------------------------------------------------

window.slugifyProvider = slugifyProvider

// Initialisation sur toutes les pages
export class View {
  static init() {
    ieCustomEvent()
    SCROLLER.init()
    PARALLAX.init({ customScroll: true })
    SCROLLFIRE.init({ customScroll: true, mobile: true })
    removeHoverOnMobile()
    svg4everybody()
    overlayShare()
    overlayDropdown()
    overlaySearch()
    overlayMenu()
    displayImages()
    clearInput()
    checkInput()
    fixObjectFitIE()
    fastResults('#f_search')
    formSearch('#f_search','#f_search_submit')
    scrollToBlock({ buttons: '.js-back-to-top', destination: 'html, body' })
  }

  static leave() {
    setTimeout(() => {
      SCROLLER.destroy()
      PARALLAX.destroy()
      SCROLLFIRE.destroy()
      OBSERVER.allOff()
    }, 400)
  }
}


// Classe initialisant les scripts présents dans la page d'accueil
export class Home extends View {
  static initHome() {
    this.init()
  }

  static leaveHome() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la carte interactive
export class Map extends View {
  static initMap() {
    this.init()
    drawers('.drawersParcours')
    overlayOeuvre()
    overlayParcours()
    mapPinMobile()
    googleMap()
  }

  static leaveMap() {
    this.leave()
    destroyMap()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'un parcours
export class DetailParcours extends View {
  static initDetailParcours() {
    this.init()
    slickImages()
    sizeH1()
    backFixed()
    sticky()
  }

  static leaveDetailParcours() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'une oeuvre
export class DetailOeuvre extends View {
  static initDetailOeuvre() {
    this.init()
    slickImages()
    sizeH1()
    drawers('.drawers')
    backFixed()
    sticky()
    playAudio()
  }

  static leaveDetailOeuvre() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de détail d'un artiste
export class DetailArtiste extends View {
  static initDetailArtiste() {
    this.init()
    slickImages()
    sizeH1()
    drawers('.drawers')
    backFixed()
    sticky()
    playAudio()
  }

  static leaveDetailArtiste() {
    this.leave()
  }
}


// Classe initialisant les scripts présents dans la page de résultats de recherche
export class SearchResults extends View {
  static initSearchResults() {
    this.init()
    dragAndSlide()
  }

  static leaveSearchResults() {
    this.leave()
  }
}
