//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import { OBSERVER, SCROLLER } from './../main.js'
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock(object) {
  object.destination === undefined ? object.destination = 0 : object.destination
  object.duration    === undefined ? object.duration = 600  : object.duration
  object.offset      === undefined ? object.offset = 0      : object.offset

  OBSERVER.add('scrollToBlock', 'click', onClick, object.buttons)
  OBSERVER.on('scrollToBlock')

  function onClick() {
    let destination
    destination = (object.destination === 0 ? object.destination : SCROLLER.getElementOffset(document.querySelector(object.destination)).top)
    if($(window).width() <= 1024) object.offset = 0
    $('html, body').animate({ scrollTop: destination + object.offset }, object.duration, 'easeInOutExpo')
  }
}


// Fonction initialisant les tiroirs
export function drawers(drawers) {
  let changeHeight = new Event('changeHeight')

  $(drawers + ' > li .toggle').on('click', function() {
    let ouvert = $(this).parent().hasClass('open')
    $(drawers + ' li.open .drawer').animate({ height: 'hide' }, { step: () => { dispatchEvent(changeHeight)}}, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $(drawers + ' li.open').removeClass('open')
    if (!ouvert) {
      let chosen = this
      setTimeout(() => { $(chosen).parent().addClass('open') }, 0)
      $('.drawer', $(this).parent()).stop(true, false).animate({ height: 'show' }, { step: (now, fx) => { dispatchEvent(changeHeight) } }, { duration: 600, specialEasing: { height: 'easeOutExpo' }})
      setTimeout(() => { $('html, body').animate({ scrollTop: $(chosen).offset().top }, 600, 'easeInOutExpo') }, 600)
    }
    return false
  })
}


// Fonction gèrant l'image à afficher dans le dropdown
export function displayImages() {
  let i, images, imagesLength, newImage
  let doc = document
  let imagesWrapper = doc.querySelector('#overlayDropdown picture')
  let placeholder = doc.createElement('img')

  OBSERVER.add('displayImages', 'mouseenter', mouseenter, '#overlayDropdown .list a')
  OBSERVER.on('displayImages')

  function mouseenter(e) {
    // Création de l'image à ajouter
    newImage = doc.createElement('img')
    newImage.src = e.target.dataset.image
    if (e.target.dataset.alt !== undefined)
      newImage.alt = e.target.dataset.alt

    // Préparation à retirer les images précédentes s'il y en a
    images = doc.querySelectorAll('#overlayDropdown img')
    imagesLength = images.length
    for (i=0; i<imagesLength; i++)
      images[i].classList.add('remove')

    // Empêche d'afficher l'image si elle n'est pas chargé
    placeholder.src = e.target.dataset.image
    placeholder.onload = () => {
      imagesWrapper.appendChild(newImage)
    }

    // Retrait des images précédentes
    setTimeout( () => {
      images = doc.querySelectorAll('#overlayDropdown img')
      imagesLength = images.length
      for (i=0; i<imagesLength; i++) {
        if(images[i].classList.contains('remove'))
          images[i].remove()
      }
    }, 300)
  }
}


// Détermine la grosseur du h1 en fonction du nombre de caractères
export function sizeH1() {
  let h1 = document.querySelector('.s_pageTitle h1')

  if(h1.textContent.length > 35)
    h1.classList.add('smaller')
}


// Fonction gérant le bouton retour à la liste
export function backFixed() {
  let back = document.getElementById('backFixed')

  OBSERVER.add('backFixed', SCROLLER.getScrollEvent(), onScroll)
  OBSERVER.on('backFixed')

  function onScroll() {
    let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let wh = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
    if (SCROLLER.getPageOffset() > 300 && (SCROLLER.getPageOffset() < (SCROLLER.getElementOffset(document.querySelector('.copyright')).top - wh) || ww > 1024))
      back.classList.add('active')
    else
      back.classList.remove('active')
  }
}


// Fonction permettant le position sticky
export function sticky() {
  let doc = document
  let selectorFixed  = doc.getElementById('sticky')
  let selectorStatic = doc.getElementById('static')
  let scroll, stickyToTop, stickyToBottom
  let sticky = doc.querySelector('.sticky')
  let stickyTo = doc.querySelector('.stickyTo')

  onResize()

  OBSERVER.add('resizeSticky', 'resize', onResize, window)
  OBSERVER.add('sticky', SCROLLER.getScrollEvent(), onScroll)
  OBSERVER.add('sticky', 'changeHeight', onScroll)
  OBSERVER.on('resizeSticky')
  OBSERVER.on('sticky')

  function onScroll() {
    scroll = SCROLLER.getPageOffset()
    stickyToTop = SCROLLER.getElementOffset(stickyTo).top
    stickyToBottom = SCROLLER.getElementOffset(stickyTo).top + stickyTo.clientHeight

    if (stickyToTop - scroll <= 0 && stickyToBottom - scroll >= sticky.clientHeight) {
      selectorFixed.classList.add('active')
      selectorStatic.classList.remove('active')
    } else if (stickyToBottom - sticky.clientHeight <= scroll) {
      selectorFixed.classList.remove('active')
      selectorStatic.classList.remove('top')
      selectorStatic.classList.add('active')
      selectorStatic.classList.add('bottom')
    } else {
      selectorFixed.classList.remove('active')
      selectorStatic.classList.remove('bottom')
      selectorStatic.classList.add('active')
      selectorStatic.classList.add('top')
    }
  }

  function onResize() {
    let ww = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    let elementHeight = document.getElementById('static').offsetHeight

    if (ww <= 1024) {
      OBSERVER.off('sticky')
      stickyTo.style.minHeight = ''
    } else {
      OBSERVER.on('sticky')
      stickyTo.style.minHeight = elementHeight+'px'
    }
  }
}


// Permet de slider les catégories lorsqu'ils sont à l'horizontale
export function dragAndSlide() {
  const slider = document.querySelector('.dragAndSlide')
  let i, startX, timeout, scrollLeft, isDown = false, preventDefault = false

  let links = document.querySelectorAll('dragAndSlide a')
  let linksLength = links.length

  for (i = 0; i < linksLength; i++)
    links[i].ondragstart = function() { return false }

  function mouseDown(e) {
    isDown = true
    slider.classList.add('active')
    startX = e.pageX - slider.offsetLeft
    scrollLeft = slider.scrollLeft
    timeout = setTimeout(() => {
      preventDefault = true
    }, 300)
  }

  function mouseleave() {
    isDown = false
    slider.classList.remove('active')
  }

  function mouseup() {
    clearTimeout(timeout)
    isDown = false
    slider.classList.remove('active')
  }

  function mousemove(e) {
    if(!isDown) return
    e.preventDefault()
    const x = e.pageX - slider.offsetLeft
    const walk = (x - startX) * 2
    slider.scrollLeft = scrollLeft - walk
  }

  function click(e) {
    if(preventDefault) {
      e.preventDefault()
      e.stopPropagation()
    }
    preventDefault = false
  }

  OBSERVER.add('dragAndSlide', 'mousedown', mouseDown, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mouseleave', mouseleave, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mouseup', mouseup, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'mousemove', mousemove, '.dragAndSlide')
  OBSERVER.add('dragAndSlide', 'click', click, '.dragAndSlide a')
  OBSERVER.on('dragAndSlide')
}

export function playAudio() {
  var audio = document.getElementById('myAudio')
  if (audio){

    audio.style.display = "none"
    
    $('#playAudio').on('click', function() {
      audio.style.display = "inline"
      audio.play()
    })
  }
}

//Permet d'enlever les accents d'une string
export function slugifyProvider(provider) {
  let result
  result = provider.replace('é', 'e')
  result = result.replace('É', 'E')
  result = result.replace('œ', 'oe')
  result = result.replace('Œ', 'Oe')
  result = result.toLowerCase()

  return result
}