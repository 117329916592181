//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Création du slick des images
export function slickImages() {
  $('#slickImages').on('init reInit afterChange', function(event, slick, currentSlide, nextSlide) {
    $('.slickDots ul li').css('height', 105 / slick.slideCount + 'px')
  })

  $('#slickImages').slick({
    appendDots: '.slickNav .slickDots',
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    draggable: true,
    speed: 500,
    autoplaySpeed: 6000,
    pauseOnHover: false
  })

  fixSlickAnim('#slickImages')

  $('.slickNav .slideNumber').on('click', () => {
    $('#slickImages').slick('next')
  })

  $('#slickImages').on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let slidePosition = nextSlide + 2 < 10 ? '0' + (nextSlide + 1) : (nextSlide + 1)
    $('.slickNav .slideNumber span').html(slidePosition)
  })
}


// Fix jumping animation on carousel
function fixSlickAnim(slider) {
  $(slider).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
    let direction, slideCountZeroBased = slick.slideCount - 1

    if (nextSlide == currentSlide)
      direction = 'same'
    else if (Math.abs(nextSlide - currentSlide) == 1)
      direction = (nextSlide - currentSlide > 0) ? 'right' : 'left'
    else
      direction = (nextSlide - currentSlide > 0) ? 'left' : 'right'

    if (direction == 'right')
      $('.slick-cloned[data-slick-index="' + (nextSlide + slideCountZeroBased + 1) + '"]', $(slider)).addClass('slickAnimate')

    if (direction == 'left')
      $('.slick-cloned[data-slick-index="' + (nextSlide - slideCountZeroBased - 1) + '"]', $(slider)).addClass('slickAnimate')
  })

  $(slider).on('afterChange', () => {
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
    $('.slickAnimate', $(slider)).removeClass('slickAnimate')
  })
}


// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function() {
    $(carousel).slick('unslick')
  }, delai)
}
