//------------------------\\
//---- Class Observer ----\\
//------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Observer manage all event listeners
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class Observer {
  constructor() {
    this.object = {}
  }


  // Turn on a specific event listener
  on(name) {
    let i, id, objSize = this.getSize(this.object)

    for (i = 0; i < objSize; i++) {
      id = 'event' + i
      if (this.object[id].name === name)
        this.condition('add', true, id)
    }
  }


  // Turn off a specific event listener
  off(name) {
    let i, id, objSize = this.getSize(this.object)

    for (i = 0; i < objSize; i++) {
      id = 'event' + i
      if (this.object[id].name === name)
        this.condition('remove', false, id)
    }
  }


  // Turn on all event listeners
  allOn() {
    let i, id, object, objectLength
    let objSize = this.getSize(this.object)

    for (i = 0; i < objSize; i++) {
      id = 'event' + i
      this.condition('add', true, id)
    }
  }


  // Turn off all event listeners
  allOff() {
    let i, id, object, objectLength
    let objSize = this.getSize(this.object)

    for (i = 0; i < objSize; i++) {
      id = 'event' + i
      this.condition('remove', false, id)
    }
  }


  // Add an instance of an event in the object
  add(name, event, myFunction, target) {
    let id = 'event' + this.getSize(this.object)
    target === undefined ? target = '' : target
    this.object[id] = {}
    this.object[id].name = name
    this.object[id].active = false
    this.object[id].target = target
    this.object[id].event = event
    this.object[id].myFunction = myFunction
  }


  // Remove an instance of an event in the object
  remove(name) {
    let i, id, objSize = this.getSize(this.object)
    for (i = 0; i <= objSize; i++) {
      id = 'event' + i
      if (this.object[id].name === name)
        break
    }

    delete this.object[id]
  }


  // Remove all instances of all events in the object
  removeAll() {
    this.object = {}
  }


  // Get the events object
  getEvents() {
    console.log(this.object)
  }


  // Manage the events listener by adding or removing them
  condition(action, status, id) {
    let i,
        object,
        objectLength,
        target = this.object[id].target,
        event = this.object[id].event,
        isActive = (action === 'add' ? !this.object[id].active : this.object[id].active)

    if (isActive) {
      this.object[id].active = status
      if (target === '') {
        window[action + 'EventListener'](event, this.object[id].myFunction)
      } else if (target == window || target == 'window') {
        window.window[action + 'EventListener'](event, this.object[id].myFunction)
      } else {
        object = document.querySelectorAll(target)
        objectLength = object.length
        for (i = 0; i < objectLength; i++)
          object[i][action + 'EventListener'](event, this.object[id].myFunction)
      }
    }
  }


  // Get the size of an object
  getSize(o) {
    let s = 0, key
    for (key in o) {
      if (o.hasOwnProperty(key)) s++
    }
    return s
  }
}
