////////////////////////////////////////////
// Fonctions reliées à la carte
////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { HTMLMarker, initMarker } from './mapMarker.js'
import { mapStyle } from './mapStyle.js' // récupère le style de la carte
import { mapOverlay } from './mapOverlay.js' // récupère l’objet de gestion des panels
import { mapFilter } from './mapFilter.js' // récupère l’objet de gestion des panels
import { mapUrl } from './mapUrl'

const $loaderId = '#googleMapLoading'
const $containerId = '#mapLayer'

let $loader = null          // Loader jquery element
let $container = null       // Map container jquery element
let map = null              // Google map reference
let pois = null             // List of the POIs
let categories = null       // categories
let activeKMLOnLoad = null  // Unique KML marker on loading.

/**
 * Lazy loading of Google Map. Possibly called multiple times in 1 page
 */
export function googleMap() {

  if (!map) {

    initMap()
    loadCategories()

    // Wait for gmap to be loaded AND displayed before adding the first markers
    google.maps.event.addListenerOnce(map, 'tilesloaded', event => {
      refreshMarkers(map)
    })

  } else {

    // Wait for fade animation to end and reset bounds
    refreshMarkers(map)
  }
}

/**
 * Initialize all google map components
 */
function initMap() {

  const mapOption = {
    zoom: 14,
    streetViewControl: false,
    fullscreenControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
      style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      mapTypeIds: [
        google.maps.MapTypeId.ROADMAP,
        google.maps.MapTypeId.SATELLITE
      ]
    },
    gestureHandling: 'cooperative',
    center: new google.maps.LatLng(48.4389803, -68.5349704),
    styles: mapStyle,
  }

  $loader = $($loaderId)
  $container = $($containerId)
  map = new google.maps.Map(document.getElementById($container.attr('id')), mapOption)

  // initialisation des marqueurs suite au chagement de Google
  mapUrl.onLoadInit()
  initMarker()
  mapOverlay.init()

  // En mobile, un click sur la carte referme l'overlay du menu.
  if ( $(window).width() > 1024 )
    $('#mapLayer').removeClass('closeLocationsOverlay')
  else
    $('#mapLayer').addClass('closeLocationsOverlay')
}

/**
 * De-reference all google map components. Called on page change
 */
export function destroyMap() {

  // Reset UI
  let event = new CustomEvent('panel.hide')
  window.dispatchEvent(event)
  mapFilter.resetUISubCategory()
  mapFilter.closeAllSubCategory()

  map = null
  pois = null
}

/**
 * Initialize an ajax request to update the markers
 * Ignore if the map hasn't been initialized yet (since it's lazy loaded)
 */
export function refreshMarkers() {

  if (!map) return
  showLoader()

  // recuperation de tous les POIS
  $.ajax({
    method: 'GET',
    url: '/api/v1/pois',
  }).done(resp => {
    if (resp.status === 'success') {
      
      updateMarkers(resp.data)      
      mapFilter.init()
      checkForKMLOnLoad()
      HTMLMarker.fitBounds()
      hideLoader()      
    }
  })
}

function checkForKMLOnLoad() {

  // KML On Load.
  if(activeKMLOnLoad)
  {
    HTMLMarker.hideAll()
    HTMLMarker.showUniqueMarker(activeKMLOnLoad)
  }
}

/**
 * Ajoute les marqueurs en overlay
 */
function updateMarkers(data) {

  var pois = data.pois

  pois.map(poi => {
    const markerPos = poi.position
    var htmlMarker = new HTMLMarker(map, markerPos.lat, markerPos.lng, poi.id, poi.slug, poi.icon ,poi.kml, poi.categoriesID, poi.accessibility)
    if (htmlMarker.activeOnLoad && htmlMarker.kmlUrl)
      activeKMLOnLoad = htmlMarker.id
  })
}

function showLoader() {
  $loader.stop().fadeIn()
}

function hideLoader() {
  $loader.stop().fadeOut()
}

function loadCategories() {

  $.ajax({
    method: 'GET',
    url: '/api/v1/pois/categories',
  }).done(resp => {
    if (resp.status === 'success') {
      categories = resp.data.poisCategoriesList
      window.poisCategories = categories
    }
  })
}

export function boundMap() {

  let bounds = new google.maps.LatLngBounds()
  map.fitBounds(bounds, 1)
  let zoom = map.getZoom()
  map.setZoom(zoom)
}

// Gère le bouton qui ouvre le menu de la carte en mobile
export function mapPinMobile() {

  $('#btnMapPins').on('click', () => {

    if (!$('html').hasClass('mapMenu'))
    {
      $('html').addClass('mapMenu')
      UI.Oeuvre.close()
      HTMLMarker.desactiveAll()
      if (mapUrl.getSlugLocalisation())
        mapUrl.setSlugLocalisation(null)
    }
    else
    {
      $('html').removeClass('mapMenu')
    }
  })

  $('.closeLocationsOverlay').on('click', function() {
    $('html').removeClass('mapMenu')
  })

  $('#mapPin .drawersLVL2Content').on('click', 'li a', function() {
    $('html').removeClass('mapMenu')
  })
}

export function initMapHeight() {

  var windowHght
  var alertHght = $('#alertBar').height()
  var headerHght = $('header').height() - alertHght

  if ( $('html').hasClass('show-alert') ) windowHght = $(window).height() - (headerHght + alertHght)
  else windowHght = $(window).height() - headerHght
  $('#mapWrapper').css('height', windowHght + 'px')

  $(window).resize(function () {
    var alertHght = $('#alertBar').height()
    var headerHght = $('header').height() - alertHght
    if ( $('html').hasClass('show-alert') ) windowHght = $(window).height() - (headerHght + alertHght)
    else windowHght = $(window).height() - headerHght
    $('#mapWrapper').css('height', windowHght + 'px')
  })
}

export function initAddressAutoComplete() {

  var options = {
    types: ['address'],
    componentRestrictions: {country: 'ca'}
  }

  var places = new google.maps.places.Autocomplete(document.getElementById('adresse'), options)

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      var geolocation = {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      }

      var circle = new google.maps.Circle({center: geolocation, radius: position.coords.accuracy})
      places.setBounds(circle.getBounds())
    })
  }
}